export enum RoleEnum {

    Employe = 'Employé',
    Manager = 'Responsable',
    RegionnalRH = 'Régionnal RH',
    ResponsableRegional = 'Responsable régional',
    DirecteurRegional = 'Directeur régional',
    ChefStation = 'Chef de station',
    ChargeDeclaration = 'Chargé déclaration',
    ChargeArchive = 'Chargé archive',
    ChargeDeclarationCoursier = 'Chargé déclaration coursiers'
  }
