import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'environments/environment';
import { SweetAlertService } from 'app/core/services/sweet-alert.service';
import { AuthService } from '../auth.service';
import { ResetPasswordService } from './reset-password.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
// THIS COMPONENT IS USED TO RESET THE TEMP PASSWORD OF A USER IN THE FIRST LOGIN
export class ResetPasswordComponent {
    resetForm: FormGroup;
    userEmail: string;
    passwordsDoNotMatch: boolean = false;
    currentUserEmail: string;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private fb: FormBuilder,
        private router: Router,
        private sweetAlertService: SweetAlertService,
        private authService: AuthService,
        private resetPasswordService: ResetPasswordService
    ) {
        this.userEmail = this.route.snapshot.params['userEmail'];
        this.currentUserEmail = this.authService.currentUser?.email; // Logged-in user's email

        // Check if the route email matches the current user's email
        if (!this.currentUserEmail) {
            // If not, redirect to login or log out
            this.router.navigate(['/login']);
        } else if (this.userEmail !== this.currentUserEmail) {
            // If not, redirect to login or log out
            this.router.navigate(['/reset-password/', this.currentUserEmail]);
        }

        this.resetForm = this.fb.group({
            newPassword: ['', [Validators.required]],
            confirmPassword: ['', [Validators.required]],
        });
    }

    checkPasswords() {
        const newPassword = this.resetForm.get('newPassword')?.value;
        const confirmPassword = this.resetForm.get('confirmPassword')?.value;
        this.passwordsDoNotMatch = newPassword !== confirmPassword;
    }

    onSubmit() {
        this.checkPasswords();

        if (this.resetForm.valid && !this.passwordsDoNotMatch) {
            const newPassword = this.resetForm.get('newPassword')?.value;

            this.http
                .post(
                    `${environment.api}auth/reset-password/${this.userEmail}`,
                    {
                        password: newPassword,
                    }
                )
                .subscribe(() => {
                    this.sweetAlertService.creationSucces(
                        'Mot de passe changé avec succès'
                    );
                    this.authService.logout(); // Log the user out after resetting the password
                    this.router.navigate(['/login']); // Redirect to login page
                });
        }
    }
}
