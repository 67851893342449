import { FuseNavigationItem } from "@fuse/components/navigation";

export const menuManager: FuseNavigationItem[] =
    [
        {
            id: 'Menu Responsable',
            title: 'Responsable',
            type: 'group',
        },
        {
            id: 'dashboard responsable',
            title: 'Dashboard',
            subtitle: 'Dashboard responsable',
            type: 'basic',
            icon: 'heroicons_outline:home',
            link: '/manager/dashboard'
        },
        {
            id: 'Gestion des employés',
            title: 'Gestion des employés',
            type: 'collapsable',
            icon: 'heroicons_outline:user-group',
            children: [
                {
                    id: 'Listes des employés ',
                    title: 'Liste des employés',
                    type: 'basic',
                    icon: 'heroicons_outline:user-group',
                    link: 'manager/employes/list-employes'

                },
          
            ]
        },
        {
            id: 'Carrière',
            title: 'Carrière',
            type: 'collapsable',
            icon: 'heroicons_outline:briefcase',
            children: [
                {
                    id: 'demande de recrutement',
                    title: 'Demander un recrutement',
                    type: 'basic',
                    icon: 'heroicons_outline:pencil-square',
                    link: 'manager/career/recruitment-request'

                },
                {
                    id: 'liste des demandes de recrutement ',
                    title: 'Liste des demandes',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'manager/career/list-recruitment-request'


                },
            ]
        },
        {
            id: 'congé et absences',
            title: 'Congés et absences',
            type: 'collapsable',
            icon: 'heroicons_outline:calendar-days',
            children: [
                {
                    id: 'liste des demandes de congés ',
                    title: 'Liste des congés',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'manager/holiday/list-leave-requests'

                },
                {
                    id: 'Absences ',
                    title: 'Absences',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'manager/holiday/absences'

                },
            ]
        },


        {
            id: 'Demandes',
            title: 'Demandes',
            type: 'collapsable',
            icon: 'heroicons_outline:mail',
            children: [
                {
                    id: 'demandes de demission',
                    title: "Demandes de démission",
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'manager/requests/resignation-requests'

                },
            ]
        },

        
       
    ]