import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { Ilogin } from './interfaces/i-login';
import { IUserLogged } from 'app/core/interfaces/userLogged.interface';
import { JWTTokenService } from 'app/core/services/jwt.service';
import { LocalStoreManager } from 'app/core/services/local-store-manager.service';
import { UserLogged } from 'app/core/model/userLogged';
import { DBkeys } from 'app/core/model/db-keys';
import { RoleEnum } from 'app/core/enums/role.enum';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private previousIsLoggedInCheck = false;
    public loginStatus = new Subject<boolean>();
    /**
     * store login status
     */
    isLogged: BehaviorSubject<boolean>;

    /**
     * store User how sucssess login
     */
    userLogged: BehaviorSubject<object>;

    /**
     * store error information to use it in alerte
     */
    errorEvent: BehaviorSubject<any>;
    constructor(
        private http: HttpClient,
        private jwtTokenService: JWTTokenService,
        private localStorage: LocalStoreManager,
        private router: Router
    ) {
        this.initializeLoginStatus();
    }

    /**
     * function
     */
    private initializeLoginStatus() {
        this.localStorage.getInitEvent().subscribe(() => {
            this.reevaluateLoginStatus();
        });
    }

    /**
     * login Function
     * @param email
     * @param password
     * @param rememberMe
     * @returns
     */
    async login(email: string, password: string, rememberMe: boolean) {
        console.log(
            '🚀 ~ file: auth.service.ts:45 ~ AuthService ~ login ~ email:',
            email
        );
        console.log(
            '🚀 ~ file: auth.service.ts:45 ~ AuthService ~ login ~ password:',
            password
        );
        return this.http
            .post<{ access_token: string }>(`${environment.api}auth/login`, {
                email: email,
                password: password,
            })
            .pipe(
                map((response) => {
                    console.log(
                        '🚀 ~ file: auth.service.ts ~ line 52 ~ AuthService ~ map ~ response',
                        response
                    );
                    this.processLogin(response, rememberMe);
                })
            );
    }

    async processLogin(resp: { access_token: string }, rememberMe: boolean) {
        const accessToken = resp.access_token;
        // console.log("acces : " + accessToken)
        if (accessToken == null) {
            throw new Error('accessToken cannot be null');
        }
        this.jwtTokenService.setToken(accessToken);
        const decodedAccessToken = this.jwtTokenService.getDecodeToken();
        const expiresIn = +this.jwtTokenService.getExpiryTime();
        const tokenExpiryDate = new Date(0);
        tokenExpiryDate.setUTCSeconds(expiresIn);
        const accessTokenExpiry = tokenExpiryDate;
        const user = new UserLogged();
        user.id = +this.jwtTokenService.getUserId();
        user.email = this.jwtTokenService.getUserEmail();
        user.role = this.jwtTokenService.getUserRole();
        user.logo = this.jwtTokenService.getUserCompanyLogo();
        user.companyId = +this.jwtTokenService.getUserCompanyId();
        user.isTemporaryPassword =
            this.jwtTokenService.getIsTemporaryPassword();

        this.saveUserDetails(
            user,
            accessToken,
            /*refreshToken,*/ accessTokenExpiry,
            rememberMe
        );

        this.reevaluateLoginStatus(user);
        return user;
    }

    private saveUserDetails(
        user: UserLogged,
        accessToken: string,
        /*refreshToken: string,*/ expiresIn: Date,
        rememberMe: boolean
    ) {
        if (rememberMe) {
            this.localStorage.savePermanentData(
                accessToken,
                DBkeys.ACCESS_TOKEN
            );
            //this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
            this.localStorage.savePermanentData(
                expiresIn,
                DBkeys.TOKEN_EXPIRES_IN
            );
            //this.localStorage.savePermanentData(permissions, DBkeys.USER_PERMISSIONS);
            this.localStorage.savePermanentData(user, DBkeys.CURRENT_USER);
        } else {
            this.localStorage.saveSyncedSessionData(
                accessToken,
                DBkeys.ACCESS_TOKEN
            );
            //this.localStorage.saveSyncedSessionData(refreshToken, DBkeys.REFRESH_TOKEN);
            this.localStorage.saveSyncedSessionData(
                expiresIn,
                DBkeys.TOKEN_EXPIRES_IN
            );
            //this.localStorage.saveSyncedSessionData(permissions, DBkeys.USER_PERMISSIONS);
            this.localStorage.saveSyncedSessionData(user, DBkeys.CURRENT_USER);
        }
        this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    }

    /**
     *
     */
    logout() {
        // console.log("logout")
        this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
        this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
        this.localStorage.deleteData(DBkeys.TOKEN_EXPIRES_IN);
        this.localStorage.deleteData(DBkeys.USER_PERMISSIONS);
        this.localStorage.deleteData(DBkeys.CURRENT_USER);

        //this.configurations.clearLocalChanges();

        this.reevaluateLoginStatus();
        this.router.navigate(['/']);
    }

    private reevaluateLoginStatus(currentUser?: UserLogged) {
        const user =
            currentUser ||
            this.localStorage.getDataObject<UserLogged>(DBkeys.CURRENT_USER);
        const isLoggedIn = user != null;

        if (this.previousIsLoggedInCheck !== isLoggedIn) {
            setTimeout(() => {
                this.loginStatus.next(isLoggedIn);
            }, 5000);
        }
        this.previousIsLoggedInCheck = isLoggedIn;
    }

    /**
     *
     * @returns
     */
    getLoginStatusEvent(): Observable<boolean> {
        return this.loginStatus.asObservable();
    }

    get currentUser(): UserLogged {
        const accessToken = this.getJWTToken();
        if (accessToken) {
            this.jwtTokenService.setToken(accessToken);
            const decodedAccessToken = this.jwtTokenService.getDecodeToken();
            const user = new UserLogged();
            user.id = +this.jwtTokenService.getUserId();
            user.email = this.jwtTokenService.getUserEmail();
            user.role = this.jwtTokenService.getUserRole();
            user.logo = this.jwtTokenService.getUserCompanyLogo();
            user.companyId = +this.jwtTokenService.getUserCompanyId();
            user.isTemporaryPassword =
                this.jwtTokenService.getIsTemporaryPassword();
            //const user = this.localStorage.getDataObject<User>(DBkeys.CURRENT_USER);  faille de sécurité !
            this.reevaluateLoginStatus(user);

            return user;
        } else {
            return null;
        }
    }

    get rememberMe(): boolean {
        return (
            this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) ===
            true
        );
    }

    getJWTToken() {
        return this.localStorage.getDataObject<string>(DBkeys.ACCESS_TOKEN);
    }

    get isLoggedIn(): boolean {
        return this.currentUser != null;
    }

    redirectLoggedUser(user: UserLogged): Boolean {
        console.log(
            '🚀 ~ file: auth.service.ts:208 ~ AuthService ~ redirectLoggedUser ~ user:',
            user
        );
        let isRedirected: boolean = false;
        switch (user.role) {
            case 'Super admin':
                this.router.navigate(['/admin']);
                isRedirected = true;
                break;
            case 'Admin':
                this.router.navigate(['/admin']);
                isRedirected = true;
                break;
            case RoleEnum.Employe:
                this.router.navigate(['/employe']);
                isRedirected = true;
                break;
            case RoleEnum.RegionnalRH:
                this.router.navigate(['/regionnal-rh']);
                isRedirected = true;
                break;
            case RoleEnum.Manager:
                this.router.navigate(['/manager']);
                isRedirected = true;
                break;
            case RoleEnum.ResponsableRegional:
                this.router.navigate(['/regionnal-manager']);
                isRedirected = true;
                break;
            case RoleEnum.DirecteurRegional:
                this.router.navigate(['/regionnal-director']);
                isRedirected = true;
                break;
            case RoleEnum.ChefStation:
                this.router.navigate(['/agency-manager']);
                isRedirected = true;
                break;
            case RoleEnum.ChargeDeclaration:
                this.router.navigate(['/declaration-officer']);
                isRedirected = true;
                break;
            case RoleEnum.ChargeDeclarationCoursier:
                this.router.navigate(['/declaration-officer']);
                isRedirected = true;
                break;
            case RoleEnum.ChargeArchive:
                this.router.navigate(['/archival-officer']);
                isRedirected = true;
                break;
            default:
                break;
        }
        return isRedirected;
    }
}
