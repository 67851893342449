import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';

import { AuthService } from 'app/modules/auth/auth.service';
import { menuAdmin } from 'app/menus/admin.menu';
import { IUserLogged } from 'app/core/interfaces/userLogged.interface';
import { menuEmploye } from 'app/menus/employe.menu';
import { menuRegionnalRh } from 'app/menus/regionnal-rh.menu';
import { menuManager } from 'app/menus/manager.menu';
import { menuRegionnalManager } from 'app/menus/regionnal-manager.menu';
import { RoleEnum } from 'app/core/enums/role.enum';
import { menuAgencymanager } from 'app/menus/agency-manager.menu';
import { menuDeclarationOfficer } from 'app/menus/charge-declaration.menu';
import { menuRegionnalDirector } from 'app/menus/regionnal-director.enum';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: any;
    user: IUserLogged = {};
    isLogged: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private authService: AuthService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router
    ) {}

    roleMenu = {
        Admin: menuAdmin,
        'Super admin': menuAdmin,
        Employé: menuEmploye,
        'Régionnal RH': menuRegionnalRh,
        Responsable: menuManager,
        'Responsable régional': menuRegionnalManager,
        'Directeur régional': menuRegionnalDirector,
        'Chef de station': menuAgencymanager,
        'Chargé déclaration': menuDeclarationOfficer,
        'Chargé déclaration coursiers': menuDeclarationOfficer,
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
        if (this.authService.currentUser) {
            this.user.email = this.authService.currentUser['email'];
            this.user.logo = this.authService.currentUser['logo'];
            // if (this.authService.currentUser['role'] != RoleEnum.Employe) {
            //     this.navigation = this.roleMenu[this.authService.currentUser['role']].concat(this.roleMenu[RoleEnum.Employe])
            // } else {
            this.navigation =
                this.roleMenu[this.authService.currentUser['role']];
            // }
        }

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    removeDuplicates(myArray, Prop) {
        return myArray.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[Prop]).indexOf(obj[Prop]) === pos;
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
