import { FuseNavigationItem } from "@fuse/components/navigation";

export const menuDeclarationOfficer: FuseNavigationItem[] =
    [
        {
            id: 'Listes ',
            title: 'Liste des non déclarés',
            type: 'basic',
            icon: 'heroicons_outline:user-group',
            link: '/declaration-officer/list-undeclared-employes'
        },
        {
            id: 'Listes des sortants ',
            title: 'Liste des sortants',
            type: 'basic',
            icon: 'heroicons_outline:user-group',
            link: '/declaration-officer/list-outgoing-employes'
        }

    ]
