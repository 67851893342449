import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SweetAlertService } from '../../core/services/sweet-alert.service';
import { AuthService } from './auth.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    spinner: boolean = false;

    submitted = false;
    error = '';
    returnUrl: string = '';
    type: string;

    // set the currenr year
    year: number = new Date().getFullYear();

    /**
     * Creates an instance of auth component.
     * @param authservice
     * @param router
     * @param alertConfig
     */
    constructor(
        private router: Router,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private sweetAlertService: SweetAlertService
    ) {
        if (this.authService.isLoggedIn) {
            this.authService.redirectLoggedUser(this.authService.currentUser);
        }
    }
    authForm = this.formBuilder.group({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    ngOnInit() {}
    get f() {
        return this.authForm.controls;
    }

    /**
     * Determines whether login on
     */
    // async onLogin() {
    //     if (this.authForm.invalid) {
    //         this.submitted = false;
    //         return;
    //     } else {
    //         this.submitted = true;
    //         this.spinner = true;
    //         (
    //             await this.authService.login(
    //                 this.f.email.value,
    //                 this.f.password.value,
    //                 false
    //             )
    //         ).subscribe({
    //             next: async (user: any) => {
    //                 console.log('user', this.authService.currentUser);
    //                 this.authService.redirectLoggedUser(
    //                     this.authService.currentUser
    //                 );
    //                 this.spinner = false;
    //             },
    //             error: async (error) => {
    //                 if ((await error.status) === 401) {
    //                     this.sweetAlertService.sipmleAlert(
    //                         'warning',
    //                         'Email ou mot de passe incorrect',
    //                         '',
    //                         'center',
    //                         false
    //                     );
    //                 }
    //                 if ((await error.status) == undefined) {
    //                     this.sweetAlertService.basicWarning(
    //                         'Email ou mot de passe incorrect'
    //                     );
    //                 }
    //                 this.spinner = false;
    //             },
    //         });
    //     }
    // }

    async onLogin() {
        if (this.authForm.invalid) {
            this.submitted = false;
            return;
        }

        this.submitted = true;
        this.spinner = true;

        (
            await this.authService.login(
                this.f.email.value,
                this.f.password.value,
                false
            )
        ).subscribe({
            next: (response: any) => {
                const user = this.authService.currentUser;
                this.spinner = false;

                // Check if the password is temporary and redirect to reset password page
                if (user.isTemporaryPassword) {
                    this.router.navigate(['/reset-password', user.email]);
                } else {
                    // Normal flow
                    this.authService.redirectLoggedUser(user);
                }
            },
            error: (error) => {
                this.spinner = false;
                if (error.status === 401) {
                    this.sweetAlertService.sipmleAlert(
                        'warning',
                        'Email ou mot de passe incorrect',
                        '',
                        'center',
                        false
                    );
                } else if (error.status == undefined) {
                    this.sweetAlertService.basicWarning(
                        'Email ou mot de passe incorrect'
                    );
                }
            },
        });
    }
}
