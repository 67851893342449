import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
    providedIn: 'root',
})
export class JWTTokenService {
    jwtToken!: string;
    decodedToken!: { [key: string]: any };

    constructor() {}

    setToken(token: string) {
        if (token) {
            this.jwtToken = token;
            localStorage.setItem('access_token', token);
        }
    }

    decodeToken() {
        if (this.jwtToken) {
            this.decodedToken = jwt_decode(this.jwtToken);
        }
    }

    getDecodeToken() {
        return jwt_decode(this.jwtToken);
    }

    getUserId() {
        this.decodeToken();
        // return this.decodedToken ? this.decodedToken.Id : null;
        return this.decodedToken.id;
    }

    getUserEmail() {
        this.decodeToken();
        return this.decodedToken.email;
        // return this.decodedToken ? this.decodedToken.userEmail : null;
    }

    getUserRole() {
        this.decodeToken();
        return this.decodedToken.role;
    }

    getUserCompanyLogo() {
        this.decodeToken();
        return this.decodedToken.logo;
    }

    getUserCompanyId() {
        this.decodeToken();
        return this.decodedToken.companyId;
    }

    getIsTemporaryPassword() {
        this.decodeToken();
        return this.decodedToken.isTemporaryPassword;
    }

    getExpiryTime() {
        this.decodeToken();
        // return this.decodedToken ? this.decodedToken.exp : null;
        return this.decodedToken.exp;
    }

    isTokenExpired(): boolean {
        const expiryTime: number = +this.getExpiryTime();
        if (expiryTime) {
            return 1000 * expiryTime - new Date().getTime() < 5000;
        } else {
            return false;
        }
    }
}
