import { FuseNavigationItem } from '@fuse/components/navigation';

export const menuAdmin: FuseNavigationItem[] = [
    {
        id: 'Volet administratif',
        title: 'Volet administratif',
        type: 'group',
    },
    {
        id: 'dashboards admin',
        title: 'Dashboard',
        subtitle: 'Dashboard Administrateur',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/admin/dashboard',
    },

    {
        id: 'Gestion des employés',
        title: 'Gestion des employés',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
            {
                id: 'liste employes',
                title: 'Liste des employés',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'admin/employes/list-employes',
            },
            {
                id: 'create employe',
                title: 'Ajouter un employé',
                type: 'basic',
                icon: 'heroicons_outline:user-add',
                link: 'admin/employes/create-employe',
            },
            {
                id: 'Affectation des régionnaux',
                title: 'Régionnaux',
                type: 'basic',
                icon: 'heroicons_outline:user-add',
                link: 'admin/employes/affect-regional',
            },
            {
                id: 'liste des sortant',
                title: 'Liste des sortants',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'admin/employes/list-outgoing-employes',
            },
            {
                id: 'liste des suspendus',
                title: 'Liste des suspendus',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'admin/employes/list-suspended-employes',
            },
        ],
    },

    {
        id: 'Carrière',
        title: 'Carrière',
        type: 'collapsable',
        icon: 'heroicons_outline:briefcase',
        children: [
            {
                id: 'liste des demandes de recrutement ',
                title: 'Demandes recrutement',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/career/list-recruitment-request',
            },
            {
                id: 'create Offre',
                title: 'Ajouter une Offre',
                type: 'basic',
                icon: 'heroicons_outline:view-grid-add',
                link: 'admin/career/create-job-offer',
            },
            {
                id: 'liste Offres',
                title: 'Liste des Offres',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/career/list-job-offers',
            },
            {
                id: 'candidature',
                title: 'Liste des candidature',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/career/list-job-applications',
            },
            {
                id: 'entretien',
                title: 'Liste des entretiens',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/career/list-interviews',
            },
        ],
    },

    {
        id: 'Départements et services',
        title: 'Département et service',
        type: 'collapsable',
        icon: 'heroicons_outline:office-building',
        children: [
            {
                id: 'list départements',
                title: 'Liste des départements',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/departements/list-departements',
            },
            {
                id: 'list services',
                title: 'Liste des services',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/departements/list-services',
            },

            {
                id: 'list services',
                title: 'Liste des fonctions',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/departements/list-occupations',
            },
        ],
    },
    {
        id: 'Gestion des agences',
        title: 'Gestion des agences',
        type: 'collapsable',
        icon: 'heroicons_outline:office-building',
        children: [
            {
                id: 'Ajouter une agence',
                title: 'Ajouter une agence',
                type: 'basic',
                icon: 'heroicons_outline:plus-sm',
                link: 'admin/create-agency',
            },
            {
                id: 'Liste des agence',
                title: 'Liste des agence',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/list-agencies',
            },
        ],
    },
    {
        id: 'Congés',
        title: 'Congés et jours fériés ',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar-days',
        children: [
            {
                id: 'Types des congés',
                title: 'Types de congé',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/holidays/list-leaves-type',
            },
            {
                id: 'Liste des congés',
                title: 'Liste des congés',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/holidays/list-leave-requests',
            },
            {
                id: 'Jours fériés',
                title: 'Jours fériés',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'admin/holidays/list-holidays',
            },
        ],
    },
    {
        id: 'reclamation',
        title: 'Un probleme technique ?',
        subtitle: 'Deposer une reclamation',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'https://www.ireclamation.loghubconnect.com/',
        externalLink: true,
    },
];
