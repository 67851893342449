import { FuseNavigationItem } from '@fuse/components/navigation';

export const menuAgencymanager: FuseNavigationItem[] = [
    {
        id: 'Volet administratif',
        title: 'Volet administratif',
        type: 'group',
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        subtitle: 'Chef de station',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/agency-manager/dashboard',
    },

    {
        id: 'Gestion des employés',
        title: 'Gestion des employés',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
            {
                id: 'liste employes',
                title: 'Liste des employés',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'agency-manager/employes/list-employes',
            },
        ],
    },

    {
        id: 'Absences',
        title: 'Congés et absences',
        type: 'collapsable',
        icon: 'heroicons_outline:calendar-days',
        children: [
            {
                id: 'liste des demandes de congés ',
                title: 'Liste des congés',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'agency-manager/holidays/list-leave-requests',
            },
            {
                id: 'Absences ',
                title: 'Absences',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'agency-manager/holidays/absences',
            },
        ],
    },

    {
        id: 'Carrière',
        title: 'Carrière',
        type: 'collapsable',
        icon: 'heroicons_outline:briefcase',
        children: [
            {
                id: 'demande de recrutement',
                title: 'Demander un recrutement',
                type: 'basic',
                icon: 'heroicons_outline:pencil-square',
                link: 'agency-manager/career/recruitement-request',
            },
            {
                id: 'liste des demandes de recrutement ',
                title: 'Liste des demandes',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'agency-manager/career/list-recruitement-request',
            },
        ],
    },
    {
        id: 'reclamation',
        title: 'Un probleme technique ?',
        subtitle: 'Deposer une reclamation',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'https://www.ireclamation.loghubconnect.com/',
        externalLink: true,
    },
];
