<div class="flex md:justify-center w-full mx-auto flex-col flex-auto py-8 md:py-20">
    <div
        class="flex flex-col w-full mx-auto sm:w-10/12 md:max-w-3xl sm:rounded-2xl md:shadow-xl md:shadow-slate-400 overflow-hidden sm:bg-card">
        <div
            class="relative hidden md:flex flex-auto items-center justify-center p-16 lg:px-12 overflow-hidden bg-gray-800 dark:border-l">
            <!-- Background SVG -->
            <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
                preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="96" cy="123"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>

            <!-- Content -->
            <div class="w-full flex flex-col items-center">
                <div class="text-7xl font-bold leading-none text-gray-100">
                    <div>Sécuriser Votre Compte</div>
                    <!-- <div>Mot de passe</div> -->
                </div>
                <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                    Votre mot de passe semble temporaire... Vous devez le changer immédiatement!
                </div>
            </div>
        </div>

        <div class="w-full py-8 px-4 sm:p-12 md:p-16">
            <div class="w-full mx-auto sm:mx-0 items-center justify-center">

                <!-- Title -->
                <div class="text-4xl font-extrabold tracking-tight leading-tight">HRFORCE</div>
                <div class="mt-4 text-lg text-gray-600">Set New Password</div>

                <!-- Reset password form -->
                <form class="mt-8" [formGroup]="resetForm" (ngSubmit)="onSubmit()">

                    <!-- New Password field -->
                    <div class="flex-none w-full mb-3">
                        <label for="newPassword"
                            class="w-3/12 text-[15px] font-medium text-gray-900 dark:text-white">New Password *</label>
                        <div class="w-full mt-1">
                            <div class="relative">
                                <input #newPasswordField type="password" id="newPassword"
                                    class="outline-none w-full h-12 shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg
                                     focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="*********"
                                    formControlName="newPassword">
                                <button type="button"
                                    class="fa fa-eye-slash eye_2 absolute mt-3.5 right-3 cursor-pointer"
                                    (click)="newPasswordField.type === 'password' ? newPasswordField.type = 'text' : newPasswordField.type = 'password'">
                                    <mat-icon class="icon-size-5" *ngIf="newPasswordField.type === 'password'"
                                        [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                                    <mat-icon class="icon-size-5" *ngIf="newPasswordField.type === 'text'"
                                        [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Confirm Password field -->
                    <div class="flex-none w-full mb-3">
                        <label for="confirmPassword"
                            class="w-3/12 text-[15px] font-medium text-gray-900 dark:text-white">Confirm Password
                            *</label>
                        <div class="w-full mt-1">
                            <div class="relative">
                                <input #confirmPasswordField type="password" id="confirmPassword"
                                    class="outline-none w-full h-12 shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg
                                     focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                     dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="*********"
                                    formControlName="confirmPassword">
                                <button type="button"
                                    class="fa fa-eye-slash eye_2 absolute mt-3.5 right-3 cursor-pointer"
                                    (click)="confirmPasswordField.type === 'password' ? confirmPasswordField.type = 'text' : confirmPasswordField.type = 'password'">
                                    <mat-icon class="icon-size-5" *ngIf="confirmPasswordField.type === 'password'"
                                        [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                                    <mat-icon class="icon-size-5" *ngIf="confirmPasswordField.type === 'text'"
                                        [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Error message -->
                    <div *ngIf="passwordsDoNotMatch" class="text-xs text-red-600">
                        Passwords do not match
                    </div>

                    <!-- Submit button -->
                    <button class="fuse-mat-button-large w-full mt-6 bg-primary text-white" mat-flat-button
                        [disabled]="resetForm.invalid || passwordsDoNotMatch" (click)="onSubmit()">
                        Submit
                    </button>

                </form>

            </div>
        </div>


    </div>
</div>