<button
    mat-icon-button
    [matMenuTriggerFor]="settingsDrawer">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_solid:cog'"></mat-icon>
    </span>
</button>
<mat-menu class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" #settingsDrawer>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <mat-icon
                class="icon-size-7 text-current"
                [svgIcon]="'heroicons_solid:cog'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Paramétres</div>
            <button
                class="ml-auto"
                mat-icon-button
                (click)="settingsDrawer.close()">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col p-6">

      
            <!-- Scheme -->
            <div class="text-md font-semibold text-secondary">Mode</div>
            <div class="grid grid-cols-3 gap-3 justify-items-start mt-6">
                <!-- Auto -->
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'auto'"
                    matTooltip="Automatically sets the scheme based on user's operating system's color scheme preference using 'prefer-color-scheme' media query."
                    (click)="setScheme('auto')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'auto'">
                        Auto
                    </div>
                </div>
                <!-- Dark -->
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'dark'"
                    (click)="setScheme('dark')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:moon'"></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'dark'">
                        Sombre
                    </div>
                </div>
                <!-- Light -->
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config.scheme === 'light'"
                    (click)="setScheme('light')">
                    <div class="flex items-center rounded-full overflow-hidden">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:sun'"></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config.scheme !== 'light'">
                        Clair
                    </div>
                </div>
            </div>
            <hr class="my-8">
        </div>
    </div>
</mat-menu>
