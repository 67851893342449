import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}
    /**
     * active route if user is logged
     * @param route
     * @param state
     * @returns
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const user = localStorage.getItem('access_token');
        console.log(
            '🚀 ~ file: auth.guard.ts ~ line 22 ~ AuthGuard ~ user',
            user
        );
        const currentUserEmail = this.authService.currentUser?.email;
        const isResetPasswordPhase =
            this.authService.currentUser?.isTemporaryPassword;

        if (this.authService.isLoggedIn) {
            console.log('LOGGED IN');
            if (isResetPasswordPhase && currentUserEmail) {
                // If the email in the URL doesn't match the logged-in user's email, redirect
                this.router.navigate([`/reset-password/${currentUserEmail}`]);
            }
            return true;
        }
        this.authService.logout();
        return false;
    }
}
