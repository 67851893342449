import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { RoleEnum } from 'app/core/enums/role.enum';

@Injectable({
  providedIn: 'root'
})
export class DeclarationOfficerGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('access_token')
    const tokenDecode = jwt_decode(token);
    if (token && (tokenDecode['role'] == RoleEnum.ChargeDeclaration || tokenDecode['role'] == RoleEnum.ChargeDeclarationCoursier) ) {
      return true
    }
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() =>
    this.router.navigate(['notFound']));
}
}
