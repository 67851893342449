<div class="flex md:justify-center w-full  mx-auto flex-col flex-auto  py-8 md:py-20  ">
    <div
        class="flex w-full mx-auto sm:w-10/12 md: md:max-w-6xl sm:rounded-2xl md:shadow-xl md:shadow-slate-400 overflow-hidden sm:bg-card">
        <div class="w-full  py-8 px-4 sm:p-12 md:p-16 ">
            <div class="w-full  mx-auto sm:mx-0 items-center justify-center">

                <!-- Title -->

                <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">HRFORCE</div>



                <!-- Sign in form -->
                <form class="mt-8" (submit)="onLogin()" [formGroup]="authForm" #signInNgForm="ngForm">

                    <!-- Email field -->
                    <div class="flex-none w-full mb-3">
                        <label for="email" class="w-3/12  text-[15px] font-medium text-gray-900 dark:text-white">Email
                            *</label>

                        <div class="w-full mt-1 ">
                            <input type="text" id="email"
                                class="w-full h-12 shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                placeholder="nom@exemple.com" formControlName="email">
                            <div *ngIf="authForm.controls['email'].invalid && authForm.controls['email'].touched">
                                <span class="text-xs text-red-600"
                                    *ngIf="authForm.controls['email'].errors?.required">Email requis</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex-none w-full mb-3">
                        <label for="password"
                            class="w-3/12  text-[15px] font-medium text-gray-900 dark:text-white">Mot de passe *</label>

                        <div class="w-full mt-1 ">
                            <div class="relative">
                                <input #passwordField type="password" id="password"
                                    class="
                                    outline-none w-full h-12 shadow-sm bg-white border border-gray-300 text-gray-900 text-sm rounded-lg
                                     focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                      dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="*********"
                                    formControlName="password">
                                <button type="button" class="fa fa-eye-slash eye_2 absolute mt-3.5  right-3 cursor-pointer"
                                    (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                        [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                        [svgIcon]="'heroicons_solid:eye-off'"></mat-icon></button>
                            </div>


                            <div *ngIf="authForm.controls['password'].invalid && authForm.controls['password'].touched">
                                <span class="text-xs text-red-600"
                                    *ngIf="authForm.controls['password'].errors?.required">Password requis</span>
                            </div>
                        </div>
                    </div>


                    <!-- Submit button -->
                    <button class="fuse-mat-button-large w-full mt-6 bg-primary text-white" mat-flat-button
                        [disabled]="authForm.invalid" (click)="onLogin()">
                        Se connecter
                    </button>


                </form>

            </div>
        </div>
        <div
            class="relative hidden md:flex flex-auto items-center justify-center p-16 lg:px-12 overflow-hidden bg-gray-800 dark:border-l">
            <!-- Background - @formatter:off -->
            <!-- Rings -->
            <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
                preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="196" cy="23"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>

            <!-- Content -->
            <div class=" w-full">
                <div class=" text-7xl font-bold leading-none text-gray-100">
                    <div>Bienvenue dans</div>
                    <div>Notre Communauté</div>
                </div>
                <div class="mt-6 text-lg tracking-tight leading-6 text-gray-400">
                    Notre équipe est joignable par e-mail et par téléphone (7j/7j H24) et nous définissons avec vous les
                    fonctionnalités nécessaires pour améliorer votre gestion au quotidien.
                </div>

            </div>
        </div>
    </div>
</div>
