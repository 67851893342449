<div class="bg-gradient-to-r from-blue-300 to-yellow-200">
    <div class="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div class="border-t border-gray-200 text-center pt-8">
                <h1 class="text-9xl font-bold text-blue-400 mt-20">404</h1>
                <p class="text-2xl pb-8 px-12 font-medium mt-20">Oops! La page est introuvable veillez verifier l'url de la
                    page merci!!.</p>
         
            </div>
        </div>
    </div>
</div>
