import { FuseNavigationItem } from "@fuse/components/navigation";

export const menuEmploye : FuseNavigationItem[] =
    [
        {
            id: 'Menu Employé',
            title:'Volet Employé',
            type: 'group',
        },
        // {
        //     id: 'dashboard employe',
        //     title: 'Dashboard',
        //     subtitle: 'Dashboard employé',
        //     type: 'basic',
        //     icon: 'heroicons_outline:home',
        //     link: '/employe/dashboard'
        // },
 {
            id: 'career employe',
            title: 'Carrière',
            type: 'collapsable',
            icon: 'heroicons_outline:briefcase',
            children: [
                {
                    id: 'list job offer',
                    title: 'Liste des Offre',
                    type: 'basic',
                    icon: 'heroicons_outline:view-grid-add',
                    link: 'employe/career/list-job-offers'

                },
            
                {
                    id: 'mes candidature',
                    title: 'Mes candidatures',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'employe/career/my-list-job-applications'

                },
                {
                    id: 'mes entretiens',
                    title: 'Mes entretiens',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'employe/career/my-list-interviews'

                },
            ]
        },
        {
            id: 'Congés',
            title: 'Congés',
            type: 'collapsable',
            icon: 'heroicons_outline:calendar-days',
            children: [
                {
                    id: 'Demande de congé',
                    title: 'Demande de congé',
                    type: 'basic',
                    icon: 'heroicons_outline:pencil-square',
                    link: 'employe/holidays/leave-request'

                },
                {
                    id: 'Mes congés',
                    title: 'Mes congés',
                    type: 'basic',
                    icon: 'heroicons_outline:view-list',
                    link: 'employe/holidays/my-list-of-leaves'

                },

            ]

        },

        {
            id: 'requests',
            title: 'Demandes',
            type: 'collapsable',
            icon: 'heroicons_outline:mail',
            children: [
                {
                    id: 'Demande de démission',
                    title: 'Demande de démission',
                    type: 'basic',
                    icon: 'heroicons_outline:pencil-square',
                    link: 'employe/requests/resignation-request'

                },

            ]

        },

    ];
