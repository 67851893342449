import { FuseNavigationItem } from '@fuse/components/navigation';

export const menuRegionnalRh: FuseNavigationItem[] = [
    {
        id: 'Volet administratif',
        title: 'Volet administratif',
        type: 'group',
    },
    {
        id: 'dashboard',
        title: 'Dashboard',
        subtitle: 'Dashboard Régioonal',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/regionnal-rh/dashboard',
    },

    {
        id: 'Gestion des employés',
        title: 'Gestion des employés',
        type: 'collapsable',
        icon: 'heroicons_outline:user-group',
        children: [
            {
                id: 'liste employes',
                title: 'Liste des employés',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'regionnal-rh/employes/list-employes',
            },
            {
                id: 'create employe',
                title: 'Ajouter un employé',
                type: 'basic',
                icon: 'heroicons_outline:user-add',
                link: 'regionnal-rh/employes/create-employe',
            },

            {
                id: 'liste des suspendus',
                title: 'Liste des suspendus',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'regionnal-rh/employes/list-suspended-employes',
            },
        ],
    },

    {
        id: 'Carrière',
        title: 'Carrière',
        type: 'collapsable',
        icon: 'heroicons_outline:briefcase',
        children: [
            {
                id: 'liste des demandes de recrutement ',
                title: 'Demandes recrutement',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'regionnal-rh/career/list-recruitment-request',
            },
            {
                id: 'create Offre',
                title: 'Ajouter une Offre',
                type: 'basic',
                icon: 'heroicons_outline:view-grid-add',
                link: 'regionnal-rh/career/create-job-offer',
            },
            {
                id: 'liste Offres',
                title: 'Liste des Offres',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'regionnal-rh/career/list-job-offers',
            },
            {
                id: 'candidature',
                title: 'Liste des candidature',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'regionnal-rh/career/list-job-applications',
            },
            {
                id: 'entretien',
                title: 'Liste des entretiens',
                type: 'basic',
                icon: 'heroicons_outline:view-list',
                link: 'regionnal-rh/career/list-interviews',
            },
        ],
    },

    {
        id: 'absences',
        title: 'Absences',
        type: 'basic',
        icon: 'heroicons_outline:view-list',
        link: '/regionnal-rh/holiday/absences',
    },
    {
        id: 'reclamation',
        title: 'Un probleme technique ?',
        subtitle: 'Deposer une reclamation',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'https://www.ireclamation.loghubconnect.com/',
        externalLink: true,
    },
];
