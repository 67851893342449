import { Route } from '@angular/router';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthComponent } from './modules/auth/auth.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { AdminGuard } from './modules/auth/guards/admin.guard';
import { EmployeeGuard } from './modules/auth/guards/employee.guard';
import { RegionnalRhGuard } from './modules/auth/guards/regionnalRh.guard';
import { ManagerGuard } from './modules/auth/guards/manager.guard';
import { RegionnalManagerGuard } from './modules/auth/guards/regionnal-manager.guard';
import { AgencyManagerGuard } from './modules/auth/guards/agency-manager.guard';
import { DeclarationOfficerGuard } from './modules/auth/guards/declaration-officer-guard';
import { ArchivalOfficerGuard } from './modules/auth/guards/archival-officer.guard';
import { RegionnalDirectorGuard } from './modules/auth/guards/regionnal-director.guard';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { ResetPasswordGuard } from './modules/auth/guards/reset-password.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Landing routes

    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'reset-password/:userEmail',
        component: ResetPasswordComponent,
        canActivate: [ResetPasswordGuard],
    },
    { path: 'login', component: AuthComponent },

    // Admin routes
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'admin',
                loadChildren: () =>
                    import('app/modules/admin/admin.module').then(
                        (m) => m.AdminModule
                    ),
                canActivate: [AuthGuard, AdminGuard],
            },
        ],
    },

    // Employe routes
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'employe',
                loadChildren: () =>
                    import('app/modules/employe/employe.module').then(
                        (m) => m.EmployeModule
                    ),
                canActivate: [AuthGuard, EmployeeGuard],
            },
        ],
    },

    // regonnal routes
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'regionnal-rh',
                loadChildren: () =>
                    import('app/modules/regionnal-rh/regionnal-rh.module').then(
                        (m) => m.RegionnalRhModule
                    ),
                canActivate: [AuthGuard, RegionnalRhGuard],
            },
        ],
    },

    // manager routes
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'manager',
                loadChildren: () =>
                    import('app/modules/manager/manager.module').then(
                        (m) => m.ManagerModule
                    ),
                canActivate: [AuthGuard, ManagerGuard],
            },
        ],
    },

    // regionnal manager
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'regionnal-manager',
                loadChildren: () =>
                    import(
                        'app/modules/regionnal-manager/regionnal-manager.module'
                    ).then((m) => m.RegionnalManagerModule),
                canActivate: [AuthGuard, RegionnalManagerGuard],
            },
        ],
    },

    // regionnal director
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'regionnal-director',
                loadChildren: () =>
                    import(
                        'app/modules/regionnal-director/regionnal-director.module'
                    ).then((m) => m.RegionnalDirectorModule),
                canActivate: [AuthGuard, RegionnalDirectorGuard],
            },
        ],
    },

    // agency manager

    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'agency-manager',
                loadChildren: () =>
                    import(
                        'app/modules/agency-manager/agency-manager.module'
                    ).then((m) => m.AgencyManagerModule),
                canActivate: [AuthGuard, AgencyManagerGuard],
            },
        ],
    },

    // declaration officer
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'declaration-officer',
                loadChildren: () =>
                    import(
                        'app/modules/declaration-officer/declaration-officer.module'
                    ).then((m) => m.DeclarationOfficerModule),
                canActivate: [AuthGuard, DeclarationOfficerGuard],
            },
        ],
    },

    { path: '404', component: NotfoundComponent },
    { path: '**', redirectTo: '/404' },
];
