import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const isResetPasswordPhase =
            this.authService.currentUser?.isTemporaryPassword;
        console.log('isResetPasswordPhase', isResetPasswordPhase);
        const currentUserEmail = this.authService.currentUser?.email;
        const routeEmail = route.params['userEmail']; // Email from the route

        if (isResetPasswordPhase && currentUserEmail) {
            if (routeEmail !== currentUserEmail) {
                // If the email in the URL doesn't match the logged-in user's email, redirect
                this.router.navigate([`/reset-password/${currentUserEmail}`]);
                return false;
            }
        }

        // Otherwise, allow navigation
        return true;
    }
}
